import React, { FC, useState, useEffect } from "react";
import { theme } from "../../../theme";
import HeaderWithImage from "../../../components/composites/HeaderWithImage";
import Heading from "../../../components/elements/Heading";
import Description from "../../../components/elements/Description";
import { getAboutBannerImage } from "../../../utils/common";
interface BannerProps {
  serviceDetail: any;
  selectedService?: any;
}
const ServiceBanner: FC<BannerProps> = ({ serviceDetail, selectedService }) => {
  const serviceName = selectedService?.name;
  const [firstWord, setFirstWord] = useState("");
  const [otherWords, setOtherWord] = useState("");
  const [bannerData, setBannerData] = useState<any>(null);
  const text = serviceDetail?.data?.attributes?.bannerDesc;
  const id = serviceDetail?.data?.id;

  useEffect(() => {
    const bannerDataReduced = getAboutBannerImage(serviceDetail?.data?.attributes);
    setBannerData(bannerDataReduced);
  }, [id]);

  useEffect(() => {
    if (serviceName) {
      const firstSpace = serviceName?.indexOf(" ");
      const otherWords = serviceName?.slice(firstSpace);
      setFirstWord(serviceName?.split(" ")[0]);
      setOtherWord(otherWords);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceName]);
  return (
    <>
      <HeaderWithImage
        bgImage={bannerData?.bgImage}
        bgThumbnail={bannerData?.thumbnail}>
        <Heading HeadingType={"banner"} textAlignMob={"center"} textAlign={"center"} textFontWeight={theme.fontWeight.light} boldFontWeight={theme.fontWeight.bold} fontFamily={theme.font.families.oswald} fontSize={'3rem'} textColor={theme.colors.white} text={firstWord} boldText={otherWords} boldTextColor={theme.colors.white} />
        <Description textAlign="center" margin={`${theme.spacing[1]}rem auto`} lineHeight={2} textColor={theme.colors.white} textFontWeight={theme.fontWeight.light} text={text} maxWidth={'75%'} />
      </HeaderWithImage>
    </>
  );
};

export default ServiceBanner;